import React, { useEffect, useState } from 'react';
import whiteLogo from '../../assets/jyy-logo-white.png';
import './Header.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';

function Header({headerColor, headerGradient}) {

  return (
    <header style={{background: headerColor, backgroundImage: headerGradient}}>
      <div className="logo">
        <Link to="/"><img src={whiteLogo} alt="logo" /></Link>
      </div>
      <div className="nav">
        <ul>
          <li><Link to="/about-us">About Us</Link></li>
          <li><Link to="/services">Services</Link></li>
          <li><Link to="/contact" >Contact</Link></li>
        </ul>
      </div>
    </header>
  );
}

export default Header;
