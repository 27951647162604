import React from 'react';
import "./Diferencials.css"
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { useState, useRef, useEffect } from 'react';



const diferencialsContent = [
  {
    text1: "",
    text2: '"A critical ally in legal matters. JYY Risk’s expertise in dealing with HM Revenue and Customs is unmatched. Their consultancy advice is a must-have for anyone facing complex investigations."'
  },
  {
    text1: "",
    text2: '"A critical ally in legal matters. JYY Risk’s expertise in dealing with HM Revenue and Customs is unmatched. Their consultancy advice is a must-have for anyone facing complex investigations."'
  },
  {
    text1: "",
    text2: '"Exceptional due diligence and lifestyle checking! This service goes above and beyond to obtain and document facts. Their commitment to providing irrefutable evidence sets them apart in the industry."'
  },
  {
    text1: "",
    text2: '"We turned to this service for due diligence, and the results were outstanding. Their capability to investigate, obtain, and document facts with irrefutable evidence is a true asset. Highly recommended!"'
  },
  {
    text1: "",
    text2: '"Outstanding service in Asset Tracing and Recovery. Their ability to identify and recover assets, coupled with a strong understanding of legal processes, makes them an invaluable resource. Do not hesitate to enlist their expertise!"'
  },
  {
    text1: "",
    text2: '"Incredible results in intrusion detection! The covert cameras captured clear evidence, allowing us to take swift action. Their expertise in monitoring and preventing incidents is unmatched."'
  },
  {
    text1: "",
    text2: '"Exceptional service in monitoring and preventing anti-social behavior. The covert camera installations have proven instrumental in creating a secure environment. I highly recommend their expertise."'
  },
  {
    text1: "",
    text2: '"Impressed with the covert camera installation service! They discreetly captured evidence of an intrusion on our property, providing crucial information for law enforcement. Their commitment to security is unmatched."'
  }
]

function Diferencials() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliderControllerRef = useRef(null);
  const intervalRef = useRef(null);

  useEffect(() => {
    // Configurar o intervalo automático a cada 2 segundos
    intervalRef.current = setInterval(() => {
      nextSlider();
    }, 5000);

    // Limpar o intervalo quando o componente é desmontado
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [currentIndex]);

  function calculateScrollPercentage() {
    const windowWidth = window.innerWidth;

    if (windowWidth < 630) {
      return 1; // Rolar 100%
    } else if (windowWidth < 970) {
      return 0.5; // Rolar 50%
    } else {
      return 0.25; // Rolar 25% (o padrão)
    }
  }

  function prevSlider() {
    clearInterval(intervalRef.current);

    const sliderController = sliderControllerRef.current;
    const newScrollLeft = sliderController.scrollLeft - (sliderController.clientWidth * calculateScrollPercentage());
    sliderController.scrollTo({ left: newScrollLeft, behavior: 'smooth' });

    if (newScrollLeft <= 0) {
      // Se atingiu o início, rolar para o final
      sliderController.scrollTo({ left: sliderController.scrollWidth, behavior: 'smooth' });
      setCurrentIndex(diferencialsContent.length - 1);
    } else {
      setCurrentIndex((prevIndex) => Math.max(0, prevIndex - 1));
    }
  }

  function nextSlider() {
    clearInterval(intervalRef.current);

    const sliderController = sliderControllerRef.current;
    const newScrollLeft = sliderController.scrollLeft + (sliderController.clientWidth * calculateScrollPercentage());
    sliderController.scrollTo({ left: newScrollLeft, behavior: 'smooth' });

    if (newScrollLeft >= sliderController.scrollWidth - sliderController.clientWidth) {
      // Se atingiu o final, rolar para o início
      sliderController.scrollTo({ left: 0, behavior: 'smooth' });
      setCurrentIndex(0);
    } else {
      setCurrentIndex((prevIndex) => Math.min(diferencialsContent.length - 1, prevIndex + 1));
    }
  }

  return (
    <div className="diferencials">
      <div className="diferencials-1">
        <h2>Testimonials</h2>
      </div>
      <div className="diferencials-2">
        <div className="diferencials-slider-container" ref={sliderControllerRef}>
          <div className="slider-controller">
            <div className="prev">
              <IoIosArrowBack color='white' size={40} onClick={() => prevSlider()} />
            </div>
            <div className="next">
              <IoIosArrowForward color='white' size={40} onClick={() => nextSlider()} />
            </div>
          </div>
          {diferencialsContent.map((content, index) => (
            <div key={index} className={`diferencials-slider ${index === currentIndex ? 'active' : ''}`}>
              <h2>{content.text1}</h2>
              <p>{content.text2}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Diferencials;