import React from 'react';
import "./Contact.css"
import Header from '../components/Header/Header';
import Footer from '../components/Footer/Footer';

function Contact() {
  return(
    <>
    <Header />
    <div className="contact">
        <div className="contact-title">
            <h2>Contact us</h2>
        </div>
        <div className="contact-container">
            <div className="contact-form one">
                    
                    <form>
                        <h2>Contact Form</h2>
                        <input type="text" id="form_name" placeholder='Full Name' />
                        <input type="text" id="form_mail" placeholder='Email'/>
                        <input type="text" id="form_phone" placeholder='Phone'/>
                        <textarea  id="message-text" cols="30" rows="10"></textarea>
                        <button type="submit">Submit</button>
                        
                    </form>
            </div>
            <div className="contact-form two">
                
                <div className="contact-info">
                    <h2>Other Ways To Contact?</h2>
                    <h3>Telephone</h3>
                    <a href="tel:02034885067">020 3488 5067</a>
                    <h3>Email</h3>
                    <a href="mailto: jerry@jyyglobal.com">jerry@jyyglobal.com</a>
                    <h3>Address</h3>
                    <a href="#">67 Grosvenor Street<br />Mayfair<br /> London<br /> W1K 3JN</a>
                </div>
            </div>
        </div>
        <Footer />
    </div>
    
    </>
  )
}

export default Contact;