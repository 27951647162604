import React from 'react';
import "./OurTeam.css"


function OurTeam({img, text1, text2, click}) {
  return (
  <div className="our-team-card" onClick={click}>
    <img src={img} alt="our-team" />
    <div className="our-team-text">
        <h3>{text1}</h3>
        <p>{text2}</p>
    </div>
  </div>
  )
}

export default OurTeam;