import React from 'react';
import imageOne from '../../assets/about-us-banner-2.png'
import "./ImagePlusText.css"
import { Link } from 'react-router-dom';

function ImagePlusText() {
  return(
    <div className="image-plus-text-container">
        <div className="image-plus-text one">
            <div className="our-clients scroll-fade">
                <h2>Ready to find out more?</h2>
                <p>Please click on the button below and use the contact form, else email or call us to arrange for a FREE and no-obligation initial consultation with one of our Professional Consultants.</p>
                <Link to="/contact"><button>Contact Us</button></Link>
            </div>
        </div>
        <div className="image-plus-text two">
            <div className="image-plus-img"><img src={imageOne} alt="banner" /></div>
        </div>
    </div>
  )
}

export default ImagePlusText;