import './App.css';
import {Routes, Route} from 'react-router-dom'
import FrontPage from './pages/FrontPage';
import Header from './components/Header/Header';
import Leadership from './pages/Leadership';
import ScrollToAnchor from './components/ScrollToAnchor/ScrollToAnchor';
import ScrollFade from './components/scrollFade/ScrollFade';
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact'
import OpeningLoading from './components/OpeningLoading/OpeningLoading';

function App() {
  return (
    <div className="App">
        
        <Routes>
          <Route exact path='/' Component={FrontPage}/>
          <Route path='/services' Component={Leadership}/>
          <Route path='/about-us' Component={AboutUs} />
          <Route path='/contact' Component={Contact} />
        </Routes>
        
        <ScrollToAnchor />
        <ScrollFade />
        <OpeningLoading />
    </div>
  );
}

export default App;
