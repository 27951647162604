import React from 'react';
import "./Mosaic.css"


function Mosaic({mosaicItens}) {
  return (
    <div className="mosaic scroll-fade" id='mosaic'>
        {mosaicItens.map((item,index) => {
            return <div key={index} className="mosaic-item" >
              {item.icon}
              <h3>{item.title}</h3>
              <p>{item.paragraf}</p>
            </div>
        })}
    </div>
  )
}

export default Mosaic;