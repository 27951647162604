import React from 'react';
import whiteLogo from '../../assets/jyy-logo-white.png';
import "./OpeningLoading.css"

// import { Container } from './styles';

function OpeningLoading() {
  return (
    <>
    <div className="opening-loading">
        <img src={whiteLogo} alt="logo" />
    </div>
    </>
  )
}

export default OpeningLoading;