import React, { useEffect } from 'react';
import './ScrollFade.css'; // Certifique-se de criar um arquivo CSS para as animações
import { useLocation } from 'react-router-dom';

const ScrollFade = () => {
    const location = useLocation();
  useEffect(() => {
    const handleScroll = () => {
      const elements = document.querySelectorAll('.scroll-fade');

      elements.forEach((element) => {
        const rect = element.getBoundingClientRect();
        const windowHeight = window.innerHeight || document.documentElement.clientHeight;

        if (rect.top <= windowHeight && rect.bottom >= 0) {
          element.classList.add('scroll-fade-in');
        }
      });
    };

    // Adiciona um listener de scroll
    window.addEventListener('scroll', handleScroll);
    // Chama a função para verificar a visibilidade ao carregar a página
    handleScroll();

    // Remove o listener de scroll ao desmontar o componente
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location]);
};

export default ScrollFade;
