import Diferencials from "../components/Diferencials/Diferencials"
import Header from "../components/Header/Header"
import ImagePlusText from "../components/ImagePlusText/ImagePlusText"
import Mosaic from "../components/Mosaic/Mosaic"
import videos from '../assets/video-banner-2.mov'
import { Link } from "react-router-dom"

import "./FrontPage.css"


const FrontPage = () => {
    return(
        <>
        <Header />
        <div className="front-page">
            <div className="banner">
                <video autoPlay loop muted playsinline>
                    <source src={videos} />
                </video>
                <div className="banner-text scroll-fade">
                    <h2>International<br />Investigation &<br />Risk Consulting</h2>
                    <Link to="/contact"><button>Contact Us</button></Link>
                </div>
            </div>
        </div>
        </>
        
    )
}

export default FrontPage