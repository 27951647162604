import React, { useEffect } from 'react';
import Mosaic from '../components/Mosaic/Mosaic';
import "./Leadership.css"
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import { FaListCheck } from "react-icons/fa6";
import { MdOutlineLinkedCamera } from "react-icons/md";
import { RiTreasureMapLine } from "react-icons/ri";
import { PiHandCoins } from "react-icons/pi";
import { VscDebugConsole } from "react-icons/vsc";
import { FaBuildingLock } from "react-icons/fa6";
import { BsSafe } from "react-icons/bs";
import { IoDocumentsOutline } from "react-icons/io5";
import { MdOutlineSecurity } from "react-icons/md";
import { FaRegCopyright } from "react-icons/fa";
import videos2 from '../assets/services-video.mov';



const fristMosaic = [

    {
        icon: <FaListCheck size={100}/>,
        title: 'Due Diligence',
        paragraf: "Unlock the power of informed decision-making with our due diligence service. We meticulously investigate backgrounds, finances, and legal compliance, delivering tailored reports to guide you towards profitable opportunities. Don't gamble with uncertainty—choose confidence and control with our due diligence expertise."
    },
    {
        icon: <PiHandCoins size={100}/>,
        title: 'Asset Tracing',
        paragraf: "Uncover hidden assets and reclaim what's rightfully yours with our asset tracing service. Our expert investigators track down assets through meticulous research and analysis, ensuring you have the information needed to pursue legal action or recover lost funds. Don't let assets slip through the cracks—let us help you trace, locate, and reclaim with precision and efficiency."
    },
    {
        icon: <RiTreasureMapLine size={100}/>,
        title: 'Tracing',
        paragraf: "Track down elusive information and uncover hidden truths with our tracing service. Our skilled investigators utilize cutting-edge techniques to locate individuals, assets, or information, providing you with the insights needed to make informed decisions or resolve complex situations. Don't let uncertainty hold you back—harness the power of our tracing expertise to navigate confidently towards your goals."
    },
    {
        icon: <MdOutlineSecurity size={100}/>,
        title: 'GSOC',
        paragraf: 'Announcing our cutting-edge Global Security Operation Centre (GSOC) service—a bespoke crisis management solution minus the overheads of an in-house operation. Empower your security endeavors with our round-the-clock GSOC, delivering unmatched capabilities tailored to your needs. Say goodbye to complexity and hello to security excellence with our dedicated 24/7 support.'
    },
    
    {
        icon: <FaRegCopyright size={100}/>,
        title: 'Social Media & Brand Protection',
        paragraf: "Social Media & Brand Protection service—a customized solution to safeguard your online presence without the hassle of managing it in-house. Elevate your brand protection efforts with our tailored strategies, ensuring your reputation remains untarnished across all digital platforms. Say hello to peace of mind and goodbye to online threats with our expert support."
    },
    {
        icon: <MdOutlineLinkedCamera size={100}/>,
        title: 'Surveillance',
        paragraf: 'Surveillance is the practice of observing people and places without them knowing it. This includes video surveillance, audio/visual surveillance, vehicle tracking, as well as other surveillance techniques that require specialisation and total discretion in a legal and ethical way. All operatives are all highly experienced, British Police trained and equipped with the best equipment to ensure that you get the best possible results.'
    },

    
]

function Leadership() {
  

  return(
    <>
    <Header headerGradient={"linear-gradient(to bottom, rgb(0, 0, 0), rgb(0 0 0 / 76%), rgb(0 0 0 / 5%));"}/>
    <div className="leadership">
        <div className="leadership-container">
            <div className="leadership-title">
                <h2>Services</h2>
            </div>
            <Mosaic mosaicItens={fristMosaic}/>
        </div>
        <Footer /> 
    </div>
    </>
    
    
  )
}

export default Leadership;